import {Form, Row, Col, Button } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import {BACKEND, BACKEND_WEB_SOCKET} from './../config';
import { connect } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import './../App.css';
import InfoModal from '../components/InfoModal';
import MeassuringModal from '../components/MeassuringModal';
import MeasurementPdf from '../components/MeasurementPdf';
import { PDFDownloadLink } from '@react-pdf/renderer';
import CopyModal from '../components/CopyModal';

const DEFAULT_RHYTM_DISORDER = "Sinusový rytmus";
const DEFAULT_GENDER = "Muž";
const DEFAULT_MEDICATION = "Neuvedeno";
const DEFAULT_HYPERTENSION_CLASS = "Optimální";
const DEFAULT_METHOD = "Metoda 1";
const DEFAULT_CUFF_TYPE = "Klasická"; //Větší

function BloodPressureMeasurement() {
    const [gender, setGender] = useState(DEFAULT_GENDER);
    const [birthday, setBirthday] = useState("");
    const [rhytmDisorders, setRhytmDisorders] = useState(DEFAULT_RHYTM_DISORDER);
    const [medication, setMedication] = useState(DEFAULT_MEDICATION);
    const [hypertensionClassification, setHypertensionClassification] = useState(DEFAULT_HYPERTENSION_CLASS);
    const [heartRate, setHeartRate] = useState("0");
    const [weight, setWeight] = useState("0");
    const [height, setHeight] = useState("0");
    const [bmi, setBmi] = useState("0");
    const [armSize, setArmSize] = useState("0");
    const [cuffType, setCuffType] = useState(DEFAULT_CUFF_TYPE);

    const [sysPressureO	, setSysPressureO] = useState("0");
    const [meanPressureO, setMeanPressureO] = useState("0");
    const [diasPressureO, setDiasPressureO] = useState("0");
    const [sysPressureA, setSysPressureA] = useState("0");
    const [diasPressureA, setDiasPressureA] = useState("0");
    const [sysPressureClassification, setSysPressureClassification] = useState(DEFAULT_HYPERTENSION_CLASS);
    const [diasPressureClassification, setDiasPressureClassification] = useState(DEFAULT_HYPERTENSION_CLASS);

    const [graphData, setGraphData] = useState("");
    const [method, setMethod] = useState(DEFAULT_METHOD);

    const [personalId, setPersonalId] = useState("");
    const [lastBloodPressure, setLastBloodPressure] = useState({});

    const [showModal, setShowModal] = useState(false);
    const [showMeassuringDialog, setShowMeassuringDialog] = useState(false);
    const [showCopyDialog, setShowCopyDialog] = useState(false);
    const [text, setText] = useState(false);
    const [measurementSaved, setMeasurementSaved] = useState(false);

    const [data, setData] = useState({});
    let socket;

    useEffect(() => {
        if(!isNaN(parseFloat(weight)) && !isNaN(parseFloat(height))){
            let floatWeight = parseFloat(weight);
            let floatHeight = parseFloat(height) / 100.0;
            let floatBmi = floatWeight / (Math.pow(floatHeight, 2));
            setBmi(floatBmi.toFixed(2));
        }
    }, [weight, height])

    useEffect(() => {
        if(isPersonalIdValid(personalId) && personalId.length > 0){
            fetchLastData();
        }
    }, [personalId])

    useEffect(() => {
        if(lastBloodPressure && lastBloodPressure.id){
            setWeight(lastBloodPressure.weight);
            setHeight(lastBloodPressure.height);
            setRhytmDisorders(lastBloodPressure.rhytmDisorders);
            setHypertensionClassification(lastBloodPressure.hypertensionClass);
            setMedication(lastBloodPressure.medications);
            setArmSize(lastBloodPressure.armSize);
            setCuffType(lastBloodPressure.cuffType);
        }
    }, [lastBloodPressure])

    useEffect(() => {
        if(sysPressureO < 120){
            setSysPressureClassification("Optimální")
        }
        else if(sysPressureO >= 120 && sysPressureO <= 129){
            setSysPressureClassification("Normální")
        }
        else if(sysPressureO >= 130 && sysPressureO <= 139){
            setSysPressureClassification("Vysoký normální")
        }       
        else if(sysPressureO >= 140 && sysPressureO <= 159){
            setSysPressureClassification("Hypertenze stupeň 1")
        }       
        else if(sysPressureO >= 160 && sysPressureO <= 179){
            setSysPressureClassification("Hypertenze stupeň 2")
        }
        else{
            setSysPressureClassification("Hypertenze stupeň 3")
        }
    }, [sysPressureO])

    useEffect(() => {
        if(diasPressureO < 80){
            setDiasPressureClassification("Optimální")
        }
        else if(diasPressureO >= 80 && diasPressureO <= 84){
            setDiasPressureClassification("Normální")
        }
        else if(diasPressureO >= 85 && diasPressureO <= 89){
            setDiasPressureClassification("Vysoký normální")
        }       
        else if(diasPressureO >= 90 && diasPressureO <= 99){
            setDiasPressureClassification("Hypertenze stupeň 1")
        }       
        else if(diasPressureO >= 100 && diasPressureO <= 109){
            setDiasPressureClassification("Hypertenze stupeň 2")
        }
        else{
            setDiasPressureClassification("Hypertenze stupeň 3")
        }
    }, [diasPressureO])


    useEffect(() => {
        initWebSocket();

        return () => {
            closeWebSocket();
        };
    }, [])

    const initWebSocket = () => {
        socket = new WebSocket(BACKEND_WEB_SOCKET.ADDRESS);

        socket.onopen = () => {
            console.log('WebSocket connected');
        };

        socket.onmessage = (event) => {
            const message = JSON.parse(event.data);
            console.log(message)
            if (message.type === 'measurement') {
                setData(message.data);
            }
        };

        socket.onerror = (error) => {
            console.error('WebSocket error:', error);
        };

        socket.onclose = () => {
            console.log('WebSocket disconnected');
        };
    };

    const closeWebSocket = () => {
        if (socket) {
            socket.close();
        }
    };

    const closeModal = () => {
        setShowModal(false);
    }

    const closeMeassuringDialog = () => {
        setShowMeassuringDialog(false);
    }

    const closeCopyDialog = () => {
        setShowCopyDialog(false);
    }

    const onSaveMeassuringDialog = (meassuredValues, chartData) => {
        setHeartRate(meassuredValues.heartRate);
        setSysPressureO(meassuredValues.systolic);
        setMeanPressureO(meassuredValues.mean);
        setDiasPressureO(meassuredValues.diastolic);
        setGraphData(JSON.stringify(chartData));
        setShowMeassuringDialog(false);
    }

    function resetMeasurement(){
        setHeartRate("0");
        setSysPressureO("0");
        setMeanPressureO("0");
        setDiasPressureO("0");
        setSysPressureA("0");
        setDiasPressureA("0");
        setGraphData("");
        //setMethod(DEFAULT_METHOD);
        setMeasurementSaved(false);
    }

    function resetValues(){
        setGender(DEFAULT_GENDER);
        setBirthday("");
        setRhytmDisorders(DEFAULT_RHYTM_DISORDER);
        setMedication(DEFAULT_MEDICATION);
        setHypertensionClassification(DEFAULT_HYPERTENSION_CLASS);
        setHeartRate("0");
        setWeight("0");
        setHeight("0");
        setBmi("0");
        setArmSize("0");
        setCuffType(DEFAULT_CUFF_TYPE);
        setPersonalId("");
        setSysPressureO("0");
        setMeanPressureO("0");
        setDiasPressureO("0");
        setSysPressureA("0");
        setDiasPressureA("0");
        setGraphData("");
        //setMethod(DEFAULT_METHOD);
        setMeasurementSaved(false);
    }

    function fetchLastData(){
        fetch(`${BACKEND.ADDRESS}/bloodPressure/getLastMeasured?personalId=${personalId}`, 
        {credentials: 'include'})
        .then(response => response.json())
        .then(json => {
            setLastBloodPressure(json.lastBloodPressure);
        })
        .catch(error => {
            setText("Při načítání dat z databáze došlo k chybě! " + error);
            setShowModal(true);
        });
    }

    function saveBloodPressure() {
        let formatedBirthday = undefined;

        if(birthday.length > 0){
            let splitted = birthday.split(".");
            formatedBirthday = splitted[2] + "-" + splitted[1]+ "-" + splitted[0];
        }

        fetch(`${BACKEND.ADDRESS}/bloodPressure/new`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                creationDate: new Date(), gender: gender, birthday: formatedBirthday, heartRate: heartRate,
                sysPressureO: sysPressureO, meanPressureO: meanPressureO,
                diasPressureO: diasPressureO, sysPressureA: sysPressureA,
                diasPressureA: diasPressureA, medications: medication, 
                rhytmDisorders: rhytmDisorders, hypertensionClass: hypertensionClassification,
                weight: weight, height: height, bmi: bmi, graphData: graphData, method: method, personalId: personalId,
                sysPressureClassification: sysPressureClassification, diasPressureClassification: diasPressureClassification,
                armSize: armSize, cuffType: cuffType
            }),
            credentials: 'include'
        })
        .then(response => response.json())
        .then(json => {
            if(json.type === 'error'){
                setText("Při ukládání dat na server došlo k chybě!")
            }
            else{
                setText("Uložení dat proběhlo v pořádku.")
            }
        })
        .catch(error => {
            setText("Při ukládání dat na server došlo k chybě! " + error);
        }).finally(() => {
            setShowModal(true);
            setMeasurementSaved(true);
        });
    }

    function isPersonalIdValid(value){
        value = value.replace("/", "");

        if(value.length === 0){
            return true;
        }
        else{
            if(value.length !== 10 && value.length !== 9){
                return false;
            }
        }

        return true;
    }

    function setPersonalIdWithGenderAndBirthday(value){
        let isAfter54 = false;
        value = value.replace("/", "");
        setPersonalId(value);

        if(value.length === 10){
            isAfter54 = true;
        }

        let year = parseInt(value.substring(0, 2));

        if(isAfter54){
            if(year > 54){
                year += 1900;
            }
            else{
                const currentYear = new Date().getFullYear();
                year += 2000;

                if(year > currentYear){
                    year -= 100;
                }
            }
        }
        else{
            year += 1900;
        }
        
        let month = parseInt(value.substring(2, 4));
        let day = value.substring(4, 6);

        if(month > 12){
            month -= 50;
            setGender("Žena");
        }
        else{
            setGender("Muž");
        }

        if(month < 10){
            month = "0" + month;
        }

        //console.log(birthday)
        setBirthday(day + "." + month + "." + year);
    }

    function setMethodFromSettings(value){
        console.log(value)
        if(value == 1){
            setMethod("Metoda 1");
        }
        else if(value == 2){
            setMethod("Metoda 2");
        }
        else{
            setMethod("Metoda 3");
        }
    }

    function getTextToCopy(){
        const text = `Hmotnost: ${weight} kg; Výška: ${height} cm; BMI: ${bmi}\n` +
        `Tep: ${heartRate} bpm; ${method}; Oscilometrický tlak: ${sysPressureO}/${diasPressureO} mmHg; ` +
        `Střední tlak: ${meanPressureO} mmHg; Auskultační tlak: ${sysPressureA}/${diasPressureA} mmHg`;
        return text;
    }

    return (
        <div className="app">
            <div className="page">
                <div className="content">
                    <Form>
                        <Row>
                            <h2>
                                Údaje o pacientovi
                            </h2>
                        </Row>
                        <Row>
                            <Form.Group as={Col} sm="6" md="6" lg="4" className="mb-3">
                                <Form.Label><h5>Rodné číslo</h5></Form.Label>
                                <Form.Control 
                                    value={personalId} 
                                    placeholder={'000000/0000'}
                                    isInvalid={!isPersonalIdValid(personalId)} 
                                    onChange={(event)=>setPersonalIdWithGenderAndBirthday(event.target.value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col} sm="6" md="6" lg="4" className="mb-3">
                                <Form.Label><h5>Pohlaví</h5></Form.Label>
                                <Form.Select value={gender} onChange={(event)=>setGender(event.target.value)}>
                                    <option value={"Muž"}>Muž</option>
                                    <option value={"Žena"}>Žena</option>
                                    <option value={"Jiné"}>Jiné</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group as={Col} sm="6" md="6" lg="4" className="mb-3">
                                <Form.Label><h5>Datum narození</h5></Form.Label>
                                <Form.Control 
                                    value={birthday} 
                                    onChange={(event)=>setBirthday(event.target.value.replaceAll(" ", ""))}
                                    isInvalid={!/^(\d{1,2}[.]\d{1,2}[.]\d{4})$/.test(birthday) && birthday.length > 0}
                                /> 
                            </Form.Group>
                            <Form.Group as={Col} sm="6" md="6" lg="4" className="mb-3">
                                <Form.Label><h5>Hmotnost (kg)</h5></Form.Label>
                                <Form.Control 
                                    value={weight} 
                                    placeholder={'0'}
                                    isInvalid={isNaN(parseFloat(weight)) && weight.length > 0} 
                                    onChange={(event)=>setWeight(event.target.value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col} sm="6" md="6" lg="4" className="mb-3">
                                <Form.Label><h5>Výška (cm)</h5></Form.Label>
                                <Form.Control 
                                    value={height} 
                                    placeholder={'0'}
                                    isInvalid={isNaN(parseFloat(height)) && height.length > 0} 
                                    onChange={(event)=>setHeight(event.target.value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col} sm="6" md="6" lg="4" className="mb-3">
                                <Form.Label><h5>BMI</h5></Form.Label>
                                <Form.Control 
                                    value={bmi} 
                                    placeholder={'0'}
                                    disabled={true}
                                    isInvalid={isNaN(parseFloat(bmi)) && bmi.length > 0} 
                                    onChange={(event)=>setBmi(event.target.value)}
                                />
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group as={Col} sm="6" md="6" lg="4" className="mb-3">
                                <Form.Label><h5>Obvod paže (cm)</h5></Form.Label>
                                <Form.Control 
                                    value={armSize} 
                                    placeholder={'0'}
                                    isInvalid={isNaN(parseFloat(armSize)) && armSize.length > 0} 
                                    onChange={(event)=>setArmSize(event.target.value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col} sm="6" md="6" lg="4" className="mb-3">
                                <Form.Label><h5>Typ manžety</h5></Form.Label>
                                <Form.Select value={cuffType} onChange={(event) => setCuffType(event.target.value)}>
                                    <option value={"Klasická"}>Klasická</option>
                                    <option value={"Větší"}>Větší</option>
                                </Form.Select>
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group as={Col} sm="6" md="6" lg="4" className="mb-3">
                                <Form.Label><h5>Srdeční rytmus</h5></Form.Label>
                                <Form.Select value={rhytmDisorders} onChange={(event) => setRhytmDisorders(event.target.value)}>
                                    <option value={"Sinusový rytmus"}>Sinusový rytmus</option>
                                    <option value={"Četné SVES"}>Četné SVES</option>
                                    <option value={"Četné KES"}>Četné KES</option>
                                    <option value={"FISI/FLUSI"}>FISI/FLUSI</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group as={Col} sm="6" md="6" lg="4" className="mb-3">
                                <Form.Label><h5>Klasifikace hypertenze</h5></Form.Label>
                                <Form.Select value={hypertensionClassification} onChange={(event) => setHypertensionClassification(event.target.value)}>
                                    <option value={"Optimální"}>Optimální</option>
                                    <option value={"Normální"}>Normální</option>
                                    <option value={"Vysoký normální"}>Vysoký normální</option>
                                    <option value={"Hypertenze stupeň 1"}>Hypertenze stupeň 1</option>
                                    <option value={"Hypertenze stupeň 2"}>Hypertenze stupeň 2</option>
                                    <option value={"Hypertenze stupeň 3"}>Hypertenze stupeň 3</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group as={Col} sm="6" md="6" lg="4" className="mb-3">
                                <Form.Label><h5>Medikace</h5></Form.Label>
                                <Form.Select value={medication} onChange={(event) => setMedication(event.target.value)}>
                                    <option value={"Bere"}>Bere</option>
                                    <option value={"Nebere"}>Nebere</option>
                                    <option value={"Neuvedeno"}>Neuvedeno</option>
                                </Form.Select>  
                            </Form.Group>
                        </Row>
                        <hr/>  
                        <Row>
                            <h2>
                                Měření
                            </h2>
                        </Row>
                        <Row>
                            <Form.Group as={Col} sm="6" md="6" lg="4" className="mb-3">
                                <Form.Label><h5>Tep (bpm)</h5></Form.Label>
                                <Form.Control 
                                    value={heartRate} 
                                    placeholder={'0'}
                                    isInvalid={isNaN(parseFloat(heartRate)) && heartRate.length > 0} 
                                    onChange={(event)=>setHeartRate(event.target.value)}
                                />
                            </Form.Group>
                        </Row>
                        <Row>
                            <h5>
                                Data z oscilometrické metody
                            </h5>
                        </Row>
                        <Row>
                            <Form.Group as={Col} sm="6" md="6" lg="4" className="mb-3">
                                <Form.Label><h6>Systolický tlak (mmHg)</h6></Form.Label>
                                <Form.Control 
                                    value={sysPressureO} 
                                    placeholder={'0'}
                                    isInvalid={isNaN(parseFloat(sysPressureO)) && sysPressureO.length > 0} 
                                    onChange={(event)=>setSysPressureO(event.target.value)}
                                />  
                            </Form.Group>
                            <Form.Group as={Col} sm="6" md="6" lg="4" className="mb-3">
                                <Form.Label><h6>Střední tlak (mmHg)</h6></Form.Label>
                                <Form.Control 
                                    value={meanPressureO} 
                                    placeholder={'0'}
                                    isInvalid={isNaN(parseFloat(meanPressureO)) && meanPressureO.length > 0}
                                    onChange={(event)=>setMeanPressureO(event.target.value)}
                                 />   
                            </Form.Group>
                            <Form.Group as={Col} sm="6" md="6" lg="4" className="mb-3">
                                <Form.Label><h6>Diastolický tlak (mmHg)</h6></Form.Label>
                                <Form.Control 
                                    value={diasPressureO} 
                                    placeholder={'0'}
                                    isInvalid={isNaN(parseFloat(diasPressureO)) && diasPressureO.length > 0} 
                                    onChange={(event)=>setDiasPressureO(event.target.value)}
                                />  
                            </Form.Group>
                            <Form.Group as={Col} sm="6" md="6" lg="4" className="mb-3">
                                <Form.Label><h6>Klasifikace hypertenze systolického tlaku</h6></Form.Label>
                                <Form.Control 
                                    value={sysPressureClassification} 
                                    disabled
                                />  
                            </Form.Group>
                            <Form.Group as={Col} sm="6" md="6" lg="4" className="mb-3">
                                <Form.Label><h6>Klasifikace hypertenze diastolického tlaku</h6></Form.Label>
                                <Form.Control 
                                    value={diasPressureClassification} 
                                    disabled
                                />  
                            </Form.Group>
                        </Row>
                        <Row>
                            <h5>
                                Data z auskultační metody
                            </h5>
                        </Row>
                        <Row>
                            <Form.Group as={Col} sm="6" md="6" lg="4" className="mb-3">
                                <Form.Label><h6>Systolický tlak (mmHg)</h6></Form.Label>
                                <Form.Control 
                                    value={sysPressureA} 
                                    placeholder={'0'}
                                    isInvalid={isNaN(parseFloat(sysPressureA)) && sysPressureA.length > 0}  
                                    onChange={(event)=>setSysPressureA(event.target.value)}
                                /> 
                            </Form.Group>
                            <Form.Group as={Col} sm="6" md="6" lg="4" className="mb-3">
                                <Form.Label><h6>Diastolický tlak (mmHg)</h6></Form.Label>
                                <Form.Control 
                                    value={diasPressureA} 
                                    placeholder={'0'}
                                    isInvalid={isNaN(parseFloat(diasPressureA)) && diasPressureA.length > 0}  
                                    onChange={(event)=>setDiasPressureA(event.target.value)}
                                 />   
                            </Form.Group>
                        </Row>
                        <hr/>
                        <Row>
                            <div className='form-buttons'>
                                
                                {
                                    !measurementSaved && 
                                    <>
                                        <Button onClick={()=>setShowMeassuringDialog(true)} variant="outline-secondary">Měření tlaku</Button>
                                        <Button onClick={()=>saveBloodPressure()}>Uložit měření</Button>
                                        <Button onClick={()=>setShowCopyDialog(true)} variant="outline-primary">Kopírovat měření</Button>
                                    </>
                                }
                                {
                                    measurementSaved &&
                                    <>
                                        <Button onClick={()=>resetMeasurement()} variant="outline-primary" title='Vynuluje údaje o měření, zachová údaje o pacientovi.'>Opakovat měření</Button>
                                        <Button onClick={()=>resetValues()} variant="danger" title='Vynuluje údaje o měření i údaje o pacientovi.'>Nový pacient</Button>
                                        <Button variant="outline-success" className='download-pdf'>
                                            <PDFDownloadLink 
                                                document={
                                                    <MeasurementPdf 
                                                        gender={gender} birthday={birthday} heartRate={heartRate} method={method}
                                                        sysPressureO={sysPressureO} meanPressureO={meanPressureO}
                                                        diasPressureO={diasPressureO} sysPressureA={sysPressureA}
                                                        diasPressureA={diasPressureA} medication={medication}
                                                        rhytmDisorders={rhytmDisorders} hypertensionClassification={hypertensionClassification}
                                                        weight={weight} height={height} bmi={bmi} personalId={personalId}
                                                    />
                                                } 
                                                fileName="exportedMeasurement.pdf"
                                            >
                                                {({ blob, url, loading, error }) =>
                                                    loading ? 'Načítání dokumentu...' : 'Stáhnout PDF'
                                                }
                                            </PDFDownloadLink>
                                        </Button>
                                    </>
                                }
                            </div>
                        </Row> 
                    </Form>

                    <MeassuringModal 
                        show={showMeassuringDialog}
                        handleSave={onSaveMeassuringDialog}
                        handleClose={closeMeassuringDialog}
                        setMethodFromSettings={setMethodFromSettings}
                    >
                    </MeassuringModal>
                    <InfoModal text={text} show={showModal} handleClose={closeModal}></InfoModal>
                    <CopyModal text={getTextToCopy()} show={showCopyDialog} handleClose={closeCopyDialog}></CopyModal>
                </div>
            </div>
        </div>
    );
}

export default connect(
    ({ account }) => ({ account }),
  )(BloodPressureMeasurement);
