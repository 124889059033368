const BACKEND = {
    ADDRESS: 'https://bloodpressurecollector-6020.rostiapp.cz'
};

const BACKEND_WEB_SOCKET = {
    ADDRESS: 'wss://bloodpressurecollector-6020.rostiapp.cz'
};


/*
const BACKEND = {
    ADDRESS: 'http://localhost:8080'
};

const BACKEND_WEB_SOCKET = {
    ADDRESS: 'ws://localhost:8080'
};*/

export {BACKEND, BACKEND_WEB_SOCKET};